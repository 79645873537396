<div class="lead text-center mt-2 py-5">{{ 'invoiceDetails.invoiceInfo' | translate }}</div>

<div class="d-flex bd-highlight mb-1">
	<h5 class="mb-3">{{ 'invoiceDetails.contactData' | translate }}</h5>
	<div class="ml-auto bd-highlight">{{ 'invoiceDetails.requiredFields' | translate }}</div>
</div>
<app-contact-form [upgradeModel]="upgradeModel"></app-contact-form>


<div class="d-flex bd-highlight mb-1 mt-4">
	<h5 class="mb-3">{{ 'invoiceDetails.invoiceData' | translate }}</h5>
</div>
<app-invoice-form [upgradeModel]="upgradeModel"></app-invoice-form>

<div class="d-flex justify-content-end">
	<button class="btn btn-secondary" mat-button matStepperPrevious>{{ 'stepper.previous' | translate }}</button>
	<button class="btn btn-primary ml-3" [disabled]="!isCompleted" matStepperNext>{{ 'stepper.next' | translate }}</button>
</div>
