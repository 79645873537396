<section class="invoice mb-5">

	<div class="contact mb-3">
		<h5 class="mb-3">
			{{ 'invoiceDetails.invoiceAndContact' | translate }}
			<a class="ml-auto icon-edit" (click)="move(2)"></a>
		</h5>

		<h6 class="mb-0">{{ 'invoiceDetails.contactData' | translate }}</h6>
		<div>
			{{ 'invoiceDetails.salutations.' + invoiceModel?.mainContactSalutation  | translate }}
			{{ invoiceModel?.mainContactTitle}}
			{{ invoiceModel?.mainContactFirstName}}
			{{ invoiceModel?.mainContactLastName}}
		</div>
		<div>
			{{ invoiceModel?.eInvoiceEmailAddress}}
		</div>
		<div>
			{{ invoiceModel?.mainContactPhoneNumber}}
		</div>
	</div>

	<div class="company-info mt-3 mb-0">
		<h6>{{ 'invoiceDetails.invoiceData' | translate }}</h6>
		<div>{{ invoiceModel?.companyName }}</div>
		<div>{{ invoiceModel?.companyStreet }}</div>
		<div>{{ invoiceModel?.companyPostalCode }} {{ invoiceModel?.companyCity }}</div>
		<div>{{ 'countries.' + invoiceModel?.companyCountry  | translate }}</div>
		
		<div  *ngIf="invoiceModel?.vatId">{{ 'invoiceDetails.vat' | translate }}: {{ invoiceModel?.vatId }}</div>
	</div>

</section>