import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UpgradeService } from '../services/upgrade.service';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '../../environments/environment';
@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss']
})
export class UpgradeComponent {


	private token: string;
	private allowedOrigin = environment.tokenAllowedOrigin;

	constructor(
		private translate: TranslateService,
		private upgradeService: UpgradeService,
		private authService: AuthService,
		private router: Router,

	 ) {

    translate.addLangs(['en', 'de', 'ch']);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|de/) ? browserLang : 'en');

    // remove this lines for production
    // this.authService.setToken('854fe905-07b1-412d-a1af-df85c98aae11');
  // //   this.authService.setToken('b047cd85-872a-47fa-9926-0c37c2df2109'); //live
    this.authService.setToken('cfdca437-6f54-49cc-a55b-1e721ac3f430'); //integration
		this.router.navigate(['upgrade']);
	}

  @HostListener('window:message', ['$event'])
	onMessage(event) {

		if(event.origin == this.allowedOrigin && !this.token) {
			console.log('Incoming message: ', event)
			this.token = event.data.token;
			// const nonce = localStorage.getItem('cc_nonce');

			// redirect here to stepper
			// then verify token and Account with Token
			// otherwise redirect to error page
			console.log('Retrieved Token:', this.token);
			this.authService.setToken(this.token);
			// if(!!nonce) this.router.navigate(['success']);
			this.router.navigate(['upgrade']);
		}

	}

}
