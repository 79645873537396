import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UpgradeService } from '@app/services/upgrade.service';
import { AuthService } from '@app/services/auth.service';
import { StoryblokService } from '@app/services/storyblok.service';
import { ActivatedRoute } from '@angular/router';

import { concat } from 'rxjs';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {

	constructor(
		private translate: TranslateService,
		private upgradeService: UpgradeService,
		private storyblokService: StoryblokService,
		private route: ActivatedRoute
	 ) {}

	token: string;
	// storyblok content
	success: any;

  ngOnInit(): void {
  	// given token from route :token (cc)
  	// take nonce from localstorage

  	// add payment to account
  	this.initStoryblok();

    // this.route.queryParams.subscribe(params => {})

  	// load succes component from stepper
  	// this.token = this.route.snapshot.paramMap.get('token');
  	this.token = this.route.snapshot.queryParamMap.get('token');
  	const nonce = localStorage.getItem('cc_nonce');
    
    console.log(this.route.snapshot.queryParamMap);

  	if(this.token && nonce) {
  		concat(
  			this.upgradeService.postCcRetrieve({token: this.token, nonce: nonce}),
  			this.upgradeService.putCcPaymentMethod()
  		).subscribe(
  			val => console.log(val),
  			err => new Error('Failed to update payment'),
  			() => console.log('completed')
  		)
  	}

  }

  initStoryblok() {
  	this.storyblokService.getSuccess('de-de').subscribe(
  		res => this.success = res
  	)
  }


}
