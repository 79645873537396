import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validator, NG_VALIDATORS } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { TranslateService } from '@ngx-translate/core';
import { Addon } from '@app/shared/interfaces';

@Component({
  selector: 'app-addon-selector',
  templateUrl: './addon-selector.component.html',
  styleUrls: ['./addon-selector.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: AddonSelectorComponent, multi: true },
  ]
})

export class AddonSelectorComponent implements ControlValueAccessor {


	@Input() addons: Addon[] = [];
	@Input() isMonthly: Boolean = false;
	
	disabled = false;
  selectedFeatures: Addon[] = [];

	constructor(
		private modalService: NgbModal,
		private translateService: TranslateService
	) { }
	
  private onChange: (value: String[]) => void;

  toggle(addon) {
  	if(!addon.isDisabled) {
	   	addon.isSelected = !addon.isSelected;
	   	// if a addon is disabled it means that it is already included in the package
	   	// therefore it isn't possible to add it to the upgrade form value
	  	this.selectedFeatures = this.addons.filter(x => x.isSelected && !x.isDisabled == true);
	  	
	  	this.onChange(this.selectedFeatures.map(x=>x.systemIdentifier)); 		
  	}
  }

  get value(): String[] {
  	return this.addons.map(x => x.systemIdentifier);
  }

  writeValue(value: String[]) {}

  registerOnChange(fn: any):void {
    this.onChange = fn;
  }

  registerOnTouched() {}

  setDisabledState(isDisabled: boolean): void {
  	this.disabled = isDisabled;
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size : 'xl'});
  }	

  // ngOnChanges() {
  //   this.selectedFeatures = this.addons.filter(x => x.isSelected && !x.isDisabled == true);  
  //   this.onChange(this.selectedFeatures.map(x=>x.systemIdentifier));
  // }

}
