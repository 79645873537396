<div class="row">
	<div class="col-md-6 px-2" *ngFor="let product of products">
		<div
		class="product card mb-3 p-4 shadow-sm"
		(click)="selectProduct(product)"
		[class.shadow]="product === selectedProduct">
		<div class="d-flex mb-1">
			<h4 class="card-title">{{product.displayName}}</h4>
			<div class="ml-auto">
				<mat-icon [ngClass]="product === selectedProduct? 'primary':'grey'"aria-hidden="false" aria-label="Check select icon">check_circle</mat-icon>
			</div>
		</div>

			<h1 class="text-primary">{{ 'stepper.currency' | translate }} {{isMonthly ? product.priceMonthly : product.priceYearly}} {{product.price}}</h1>
			<p class="short-text" ngPreserveWhitespaces>{{product.subtitle}}</p>
			<a class="text-primary mt-5 pt-3" (click)="open(content)">{{ 'stepper.more' | translate}} {{product.displayName}} </a>
		</div>

		<ng-template #content let-modal>
			<div class="modal-header border-0">
		    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
		      <span aria-hidden="true">&times;</span>
		    </button>
			</div>
		  <div class="modal-body px-5 pb-5 mb-4">

		    <h4 class="modal-title" id="modal-basic-title">{{ 'stepper.more' | translate}} {{ product.displayName }}</h4>
		    <h1 class="text-primary">{{ 'stepper.currency' | translate }} {{isMonthly ? product.priceMonthly : product.priceYearly}} {{product.price}}</h1>

		  	<div class="row">
			  	<div class="col" *ngFor="let dialog of product.dialog; let i = index">
			  		<p class="border-bottom pb-4" *ngIf="i == 0">{{product.subtitle}}</p>
			  		<markdown [data]="dialog.markdown"></markdown>
			  	</div>
		  	</div>
		  </div>
		</ng-template>
	</div>
</div>
