<div class="lead text-center mt-2 py-5">{{ 'stepper.addonsInfo' | translate }}</div>
<div class="d-flex justify-content-center" *ngIf="!addons">
	<mat-spinner class="py-3"></mat-spinner>	
</div>
<div *ngIf="addons" [formGroup]="form">
	<div formGroupName="shoppingCart">
		<app-addon-selector 
		  [addons]="addons"
			formControlName="addonPackages"
			[isMonthly]="isMonthly"
		></app-addon-selector>
	</div>
</div>
<div class="d-flex justify-content-end">
	<button class="btn btn-secondary" mat-button matStepperPrevious>{{ 'stepper.previous' | translate }}</button>
	<button class="btn btn-primary ml-3" matStepperNext>{{ 'stepper.next' | translate }}</button>
</div>
