import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SaferPayService {

	private loaded: boolean;

	private scriptSrc: string;
	public apiUrl: string;
	public apiKey: string;

  constructor() {
  	this.loaded = false;
  	this.scriptSrc = environment.saferPayScriptSrc;
  	this.apiUrl = environment.saferPayUrl;
  	this.apiKey = environment.saferPayApiKey;	
  }

  load() {
    return new Promise((resolve, reject) => {
      // resolve if already loaded
      if (this.loaded) {
        resolve({ script: 'saferPay', loaded: true, status: 'Already Loaded' });
      } else {
        // load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scriptSrc;
        script.onload = () => {
          this.loaded = true;
          console.log('SaferPay fields script loaded.');
          resolve({ script: 'saferPay', loaded: true, status: 'Loaded' });
        };
        script.onerror = (error: any) => resolve({ script: 'saferPay', loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }
}