import { Directive, forwardRef, Attribute } from "@angular/core";
import { NG_ASYNC_VALIDATORS, Validator, AbstractControl } from "@angular/forms";
import { Observable } from "rxjs";
import { UpgradeService } from '../services/upgrade.service';

@Directive({
  selector: "[ibanValidator][formControlName], [ibanValidator][formControl], [ibanValidator][ngModel]",
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => IbanValidator), multi: true
    }
  ]
})

export class IbanValidator implements Validator {

	constructor(
    // @Attribute ('required') public required: boolean,
    private upgradeService: UpgradeService
  ) {}

  private debounceTimout;

  validate( c : AbstractControl ) : Promise<{[key : string] : any}>|Observable<{[key : string] : any}> {
    clearTimeout(this.debounceTimout);
    if(c.value){
     return this.validateIban(c.value);
    } else {
      return new Promise(resolve => resolve(null));
    }
  }

  validateIban( iban : string ) {
    return new Promise(resolve => {
      this.debounceTimout = setTimeout(() => {
        this.upgradeService.validateIban({iban})
          .subscribe(
            res => res.valid ? resolve(null) : resolve({ibanInvalid: true}),
            err => resolve({ibanInvalid: true}));
      }, 900);
    });
  }

}