import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-invoice-order',
  templateUrl: './invoice-order.component.html',
  styleUrls: ['./invoice-order.component.scss']
})
export class InvoiceOrderComponent implements OnInit {

	@Input() invoiceModel:any;
  
  @Output() moveStepper = new EventEmitter<any>();

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }

  move(stepIndex?: number): void {
  	this.moveStepper.emit(2);
  }
}
