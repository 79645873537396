<div class="row">
	<div class="col-md-4 px-2" *ngFor="let addon of addons">
		<div class="card addon mb-3 p-3  shadow-sm"
			(click)="toggle(addon)"
			disabled="addon.isDisabled"
			[class.disabled]="addon.isDisabled"
			[class.shadow]="addon.isSelected"
		>
			<div class="d-flex mb-1">
				<h5 class="card-title">{{addon.displayName}}</h5>
				<div class="ml-auto">
					<div *ngIf="addon.isDisabled">
						<div class="include"><span>{{ 'stepper.included' | translate}}</span></div>
					</div>
					<mat-icon *ngIf="!addon.isDisabled" [ngClass]="addon.isSelected ? 'primary':'grey'" aria-hidden="false" aria-label="Check select icon">check_circle</mat-icon>
				</div>
			</div>

			<h1 class="text-primary">

				{{ 'stepper.currency' | translate}}
				<span *ngIf="!addon.isDisabled; else includedPrice">
					{{ isMonthly ? addon.priceMonthly : addon.priceYearly}}
				</span>
				<ng-template #includedPrice>0.-</ng-template>

			</h1>
			<p class="short-text" ngPreserveWhitespaces>{{addon.subtitle}}</p>
			<a class="text-primary mt-4" (click)="open(content)">
				{{ 'stepper.more' | translate}} {{addon.displayName}}
			</a>
		</div>

		<ng-template #content let-modal>
			<div class="modal-header border-0">
		    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
		      <span aria-hidden="true">&times;</span>
		    </button>
			</div>
		  <div class="modal-body px-5 pb-5 mb-4">

		    <h4 class="modal-title" id="modal-basic-title">{{ 'stepper.more' | translate}} {{ addon.displayName }}</h4>
		    <h1 class="text-primary">€ {{isMonthly ? addon.priceMonthly : addon.priceYearly}} {{addon.price}}</h1>

		  	<div class="row">
			  	<div class="col" *ngFor="let dialog of addon.dialog; let i = index">
			  		<p class="border-bottom pb-4" *ngIf="i == 0">{{addon.subtitle}}</p>
			  		<markdown [data]="dialog.markdown"></markdown>
			  	</div>
		  	</div>
		  </div>
		</ng-template>

	</div>
</div>
