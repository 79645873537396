// serve with
//  ng serve --browserTarget=websms-upgrade-refactor:build:paolo
export const environment = {
  production: true,
  // Storyblok config
  storyblokApiUrl: 'https://api.storyblok.com/v1/cdn/stories/',
  storyblokApiKey: '8IszrYkA7mzQQ0IT1MiD1gtt',
  // Websms config
  websmsApiUrl: 'https://api-int.websms.com/api/v1/',
  tokenAllowedOrigin: 'https://app-int.websms.com/',
  redirectUrl: 'https://appupgrade-psc-dev.websms.com/',
  authHeader: 'beca30d3-408c-11eb-9b36-0008a210cd7a',
  // SaferPay config
  saferPayScriptSrc: 'https://test.saferpay.com/Fields/lib/1/saferpay-fields.js',
  saferPayApiKey: '177c50ca-4282-40d6-b675-544662952177',
  saferPayUrl: 'https://test.saferpay.com/Fields/253437'
};
