import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

	private token: String;

  constructor() { }

  setToken(token) {
  	this.token = token;
    localStorage.setItem('bearer', token);
  }

  getToken() {
  	return this.token || localStorage.getItem('bearer');
  }

  isAuthorized() {
    return !!this.getToken();
  }
}
