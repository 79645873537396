import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { PaymentCcComponent } from './payment-cc/payment-cc.component';
import { PaymentSepaComponent } from './payment-sepa/payment-sepa.component';

import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { UpgradeService } from '../../services/upgrade.service';

@Component({
  selector: 'app-step-complete-order',
  templateUrl: './step-complete-order.component.html',
  styleUrls: ['./step-complete-order.component.scss']
})
export class StepCompleteOrderComponent implements OnInit {

	@Input() public invoiceModel: any;
  @Input() public cart: any;
  @Input() legal: any;
  @Input() isMonthly: boolean;
  
  @Output() paymentFlow = new EventEmitter<any>();
  @Output() moveStepper = new EventEmitter<any>();

	public isCompleted = false;
	public isSepa = false;


  /**
   * Checkboxes required to be set true
   */
  public form: FormGroup;
  confirmedAgbRead = new FormControl('', Validators.requiredTrue);
  confirmedPrivacyRead = new FormControl('', Validators.requiredTrue);
  confirmedLegal = new FormControl('', Validators.requiredTrue);

	@ViewChild(PaymentCcComponent) formPaymentCcComponent: PaymentCcComponent;
	@ViewChild(PaymentSepaComponent) formPaymentSepaComponent: PaymentSepaComponent;
	

  constructor(
    private upgradeService: UpgradeService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      confirmedAgbRead: this.confirmedAgbRead,
      confirmedPrivacyRead: this.confirmedPrivacyRead,
      confirmedLegal: this.confirmedLegal
    });
  }

  ngOnInit(): void {
  }

  get buttonDisabled() {
    if(this.isSepa && this.sepaFormValid && this.form.valid) return false;
    if(!this.isSepa && this.ccFormValid && this.form.valid) return false;
    return true;
  }

  submitFlow() {
    this.isSepa ? 
      this.paymentFlow.emit({flow:'sepa', payload:this.formPaymentSepaComponent.form.value}) : 
      this.paymentFlow.emit({flow:'cc', payload:this.formPaymentCcComponent.form.value})
  }

  move(index) {
    this.moveStepper.emit(index);
  }
  // prevents click action to be set multiple times
	setSepa(val: boolean){
		if(this.isSepa != val)
			this.isSepa = val;
	}
  
  setCompleted() {
    this.isCompleted = true;
  }
  get ccFormValid() {
    return this.formPaymentCcComponent ? this.formPaymentCcComponent.form.valid  : false; 
  }

  get sepaFormValid() {
    return this.formPaymentSepaComponent ? this.formPaymentSepaComponent.form.valid : false; 
  }



}
