import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UpgradeService } from './services/upgrade.service';
import { AuthService } from './services/auth.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'websms-upgrade-refactor';

  public loading: boolean;

  constructor(
    private router: Router,
    private translate: TranslateService
  ) {

  	router.events.subscribe((routerEvent: Event) => {
  		this.checkRouterEvent(routerEvent);
  	});
  }
  checkRouterEvent(routerEvent: Event) {
  	
  	if(routerEvent instanceof NavigationStart) {
  		this.loading = true;
  	}

  	if(routerEvent instanceof NavigationEnd ||
  		 routerEvent instanceof NavigationCancel ||
  		 routerEvent instanceof NavigationError) {
  		this.loading = false;
  	}

  }
}
