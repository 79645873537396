import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { StepperComponent } from './stepper/stepper.component';
import { ErrorComponent } from './pages/error/error.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import { SuccessComponent } from './pages/success/success.component'

// import { AccountResolver } from './services/account.resolver';
import { AuthGuard } from './interceptors/auth.guard';


const routes: Routes = [
	{ path: 'upgrade', component: StepperComponent, canActivate: [AuthGuard] },
	{ path: '', component: UpgradeComponent },
	{ path: 'success', component: SuccessComponent },
	{ path: 'success/:token', component: SuccessComponent, canActivate: [AuthGuard]},
	{ path: 'error', component: ErrorComponent, data: {error: true} },
	{ path: 'cancel', component: ErrorComponent, data: {error: true} },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
