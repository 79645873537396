import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-loading-dialog",
  templateUrl: "./loading-dialog.component.html",
  styleUrls: ["./loading-dialog.component.scss"]
})
export class LoadingDialogComponent {
  constructor() {}
}
