import { Injectable } from '@angular/core';
import { Observable, of, throwError} from 'rxjs'

import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { StoryblokUpgrade } from '@app/shared/interfaces';
import { Product } from '@app/shared/interfaces';
import { Addon } from '@app/shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class StoryblokService {
	
	private apiBaseUrl = environment.storyblokApiUrl;
	private apiKey = environment.storyblokApiKey;

	private languageKey = 'de-de';

	constructor(
		private http: HttpClient
	) {}

	getUpgrade(langKey?):Observable<StoryblokUpgrade> {
		return this.createApiCall(
			null, {
			 version: 'draft',
			 starts_with: `${langKey}`
			}).pipe(
				catchError(err => this.handleError(err)),
				map(response => {
					if(!response.stories.find(story => story.name == 'products')){
						throw new Error(`No records found. Probably languageKey mismatch: ${langKey}`);
						return null;
					}	
						return {
							products: response.stories.find(story => story.name == 'products').content.body[1].columns,
							addons: response.stories.find(story => story.name == 'addons').content.body[1].columns,
							success: response.stories.find(story => story.name == 'final-page').content.body,
							legal: response.stories.find(story => story.name == 'legal').content.body
						}
				})
		);
	}

  getStory(slug: string, params?: object): Observable<any> {
  	return this.createApiCall(slug, params).pipe(
  		map(response => response.story.content)
  	);
  }

  getSuccess(langKey?): Observable<any> {
  	return this.createApiCall(`${langKey}/final-page`).pipe(
  		map(res => res.story.content.body)
  	)
  }

  createApiCall(slug?, params?): Observable<any> {
		const url = slug ? this.apiBaseUrl + slug : this.apiBaseUrl;
		params = Object.assign({
			token: this.apiKey
		}, params);
		const options = { params };
		return this.http.get(url, options).pipe(
			map(response => response),
			catchError(err => err)
		);
  }

  handleError(error): Observable<any> {
  	return of(error);
  }
}