
<div class="form-segment" [formGroup]="form">
	<div class="form-row">

		<div class="col-md-2 py-2">
			<mat-form-field class="full-width" appearance="outline">
				<mat-label>{{ 'invoiceDetails.mainContactSalutation' | translate }}</mat-label>
				<select [formControl]="mainContactSalutation" matNativeControl required>
					<option value="mr">{{ 'invoiceDetails.mainContactSalutationMr' | translate }}</option>
					<option value="mrs">{{ 'invoiceDetails.mainContactSalutationMrs' | translate }}</option>
				</select>
			</mat-form-field>
		</div>
		<div class="col-md-2 py-2">
			<mat-form-field class="full-width" appearance="outline">
				<mat-label>{{ 'invoiceDetails.mainContactTitle' | translate }}</mat-label>
				<input type="text" matInput [formControl]="mainContactTitle" placeholder="{{ 'invoiceDetails.mainContactTitle' | translate }}">
				<mat-error class="pt-2" *ngIf="mainContactTitle.invalid">{{ 'errors.forms.required' | translate }}</mat-error>
			</mat-form-field>
		</div>

	</div>

	<div class="form-row">

		<div class="col-md-6 py-2">
			<mat-form-field class="full-width" appearance="outline">
				<mat-label>{{ 'invoiceDetails.mainContactFirstName' | translate }}</mat-label>
				<input type="text" matInput [formControl]="mainContactFirstName" placeholder="{{ 'invoiceDetails.mainContactFirstName' | translate }}" required>
				<mat-error class="pt-2" *ngIf="mainContactFirstName.invalid">{{ 'errors.forms.required' | translate }}</mat-error>
			</mat-form-field>
		</div>
		<div class="col-md-6 py-2">
			<mat-form-field class="full-width" appearance="outline">
				<mat-label>{{ 'invoiceDetails.mainContactLastName' | translate }}</mat-label>
				<input type="text" matInput [formControl]="mainContactLastName" placeholder="{{ 'invoiceDetails.mainContactLastName' | translate }}" required>
				<mat-error class="pt-2" *ngIf="mainContactLastName.invalid">{{ 'errors.forms.required' | translate }}</mat-error>
			</mat-form-field>
		</div>	

	</div>

	<div class="form-row">

		<div class="col-md-6 py-2">
			<mat-form-field class="full-width" appearance="outline">
				<mat-label>{{ 'invoiceDetails.eInvoiceEmailAddress' | translate }}</mat-label>
				<input type="text" matInput [formControl]="eInvoiceEmailAddress" placeholder="{{ 'invoiceDetails.eInvoiceEmailAddress' | translate }}" email required>
				<mat-error class="pt-2" *ngIf="eInvoiceEmailAddress.invalid">{{ 'errors.forms.email' | translate }}</mat-error>
			</mat-form-field>
		</div> 
		<div class="col-md-6 py-2">
			<mat-form-field class="full-width" appearance="outline">
				<mat-label>{{ 'invoiceDetails.mainContactPhoneNumber' | translate }}</mat-label>
				<input type="text" matInput [formControl]="mainContactPhoneNumber" placeholder="{{ 'invoiceDetails.mainContactPhoneNumber' | translate }}">
				<mat-error class="pt-2" *ngIf="mainContactPhoneNumber.invalid">{{ 'errors.forms.phone' | translate }}</mat-error>
			</mat-form-field>
		</div>

	</div>

</div>