
<div class="text-center pt-4">
	<img src="./assets/icons/error.svg" alt="error" />

	<h3 class="mt-4">Oops, something went wrong.</h3>
	<p class="py-2">Leider ist ein Fehler aufgetreten. Bitte wenden Sie sich an unser Support-Team.</p>
	<p class="py-2">Wenn Sie Fragen haben, kontaktieren Sie uns gerne unter <br>support@websms.at</p>

	<button class="btn btn-error btn-block" (click)="sendMessage()">Zurück zur App</button>
</div>
