import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs'; 

import { FormGroup } from '@angular/forms';  
import { ContactFormComponent } from './contact-form/contact-form.component';
import { InvoiceFormComponent } from './invoice-form/invoice-form.component';

import { Upgrade } from '@app/shared/models/upgrade';

@Component({
  selector: 'app-step-invoice-data',
  templateUrl: './step-invoice-data.component.html',
  styleUrls: ['./step-invoice-data.component.scss']
})
export class StepInvoiceDataComponent implements OnInit, AfterViewInit {

	public isCompleted = false;
	public form: FormGroup;
  @Input() upgradeModel: Upgrade;

	@ViewChild(ContactFormComponent) formContactFormComponent: ContactFormComponent;
	@ViewChild(InvoiceFormComponent) formInvoiceFormComponent: InvoiceFormComponent;

	constructor(public translate: TranslateService) {
    this.form = new FormGroup({});
  }

  ngOnInit(): void { 
  }

  ngAfterViewInit(): void {
  	combineLatest([
  		this.contactForm.statusChanges, 
  		this.invoiceForm.statusChanges
  		]).subscribe(
  			([contact, invoice]) => {
  				if(contact == 'VALID' && invoice == 'VALID'){
  					this.isCompleted = true;
  				} else this.isCompleted = false;
  			}
  		)

		combineLatest([
			this.contactForm.valueChanges, 
			this.invoiceForm.valueChanges
			]).subscribe(
				([contact, invoice]) => this.form = {...contact, ...invoice}
			)
  }

  get contactForm() {
    return this.formContactFormComponent ? this.formContactFormComponent.form : null;
  }

  get invoiceForm() {
    return this.formInvoiceFormComponent ? this.formInvoiceFormComponent.form : null;
  }

}
