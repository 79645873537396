<div class="text-center pt-4">
	<!-- <mat-icon color="primary" class="display-4">message</mat-icon> -->
	<img src="./assets/icons/heartbubble.svg" alt="heartbubble" />
	<h3 class="mt-4">Danke für Ihre Bestellung & <br>Herzlich Willkommen bei websms!</h3>
	<p class="py-2">Ein Mitarbeiter aus unserem Customer Success-Team wird sich zeitnah <br>mit Ihnen in Verbindung setzen.</p>
	<p class="py-2">Wenn Sie Fragen haben, kontaktieren Sie uns gerne unter <br>support@websms.at</p>

	<button class="btn btn-primary btn-block" (click)="sendSuccess()">Zurück zum Dashboard</button>
</div>
<!--
<hr>

<div>
	<b class="text-primary">Keine News & Aktionen verpassen mit dem websms Newsletter</b>
	<mat-form-field class="full-width" appearance="outline">
		<mat-label>E-Mail</mat-label>
		<input type="text" matInput class="" name="newsletter"  email >
	</mat-form-field>
	<mat-checkbox name="newsletterConfirm" color="primary" required>
		Ich bin damit einverstanden, regelmäßig Informations-E-Mails von sms.at mobile internet Services GmbH zu erhalten.
		Diese Einwilligung ist jederzeit widerrufbar.
	</mat-checkbox>
	<button class="btn btn-primary" disabled>Jetzt Anmelden</button>
</div>
<p>[Add Storyblok Content]</p> -->
