import { Injectable } from '@angular/core';
import { Account } from '@app/shared/interfaces';
import { Cart} from '@app/shared/models/cart';
/**
 * Google Analytics is already added in index.html
 * @see {@link https://medium.com/quick-code/set-up-analytics-on-an-angular-app-via-google-tag-manager-5c5b31e6f41}
 */

declare global {
  interface Window { dataLayer: any[]; }
}

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  /**
   * custom events by jan.schuster
   */
	public steps = [
		'Produktauswahl',
		'Zusatzoptionen',
		'Rechnung-Kontakt',
		'Bestellung-Abschließen',
		'Bestätigung'
	]


  documentTitle = 'Upgrade - ';
	customerId: number;
	accountId: number;
	siteId: string;

  constructor() { }

  setUserIds(account: Account, siteId: string) {
  	this.accountId = account.id;
  	this.customerId = account.customer.id;
  	this.siteId = siteId;
  }

  initCustomer(){
  	window.dataLayer.push({
      documentTitle: this.documentTitle + this.steps[0],
  		customerId: this.customerId,
  		accountId: this.accountId,
  		pagePath: '/' + this.steps[0],
  		event: 'pageView',
  		siteId: this.siteId
  	});
  }

	/**
	 * GA-Event for every stepper change movements
	 */
  stepperChange(step: number) {
  	window.dataLayer.push({
      documentTitle: this.documentTitle + this.steps[step],
  		pagePath: '/'+ this.steps[step],
  		event: 'pageView',
  		customerId: this.customerId,
  		accountId: this.accountId,
  		siteId: this.siteId
  	})
  }

	/**
	 * GA-Event if user commits purchase conversion
	 */
  upgradeConversion(cart: Cart, isMonthly: boolean) {
  	window.dataLayer.push({
  		event: 'upgradeConversion',
  		customerId: this.customerId,
  		accountId: this.accountId,
  		product: cart.packages,
  		addons: cart.addons,
  		siteId: this.siteId
  	});
    window.dataLayer.push({
      event: 'purchase',
      clientId: this.customerId,
      accountId: this.accountId,
      currency: 'EUR',
      price: isMonthly ? cart.getSumMonthly() : cart.getSumYearly(),
      itemName: cart.packages[0].displayName
    });
  }


}
