import { Directive, forwardRef } from "@angular/core";
import { NG_ASYNC_VALIDATORS, Validator, AbstractControl } from "@angular/forms";
import { Observable } from "rxjs";
import { UpgradeService } from '../services/upgrade.service';

@Directive({
  selector: "[bicValidator][formControlName], [bicValidator][formControl],[bicValidator][ngModel]",
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => BicValidator), multi: true
    }
  ]
})

export class BicValidator implements Validator {

	constructor(private upgradeService: UpgradeService) {}

  private debounceTimout;

  validate( c : AbstractControl ) : Promise<{[key : string] : any}>|Observable<{[key : string] : any}> {
    clearTimeout(this.debounceTimout);
    let iban = c.root.get('accountIban').value;
    console.log(iban);
    if(c.value) {
	    if(iban) {
	    	return this.validateBic(c.value, iban);
	    } else { 
	    	return new Promise(resolve => resolve({ibanMissing: true}));
	    }
    }
    return new Promise(resolve => resolve(null));
  }

  validateBic( bic: string, iban : string ) {
    return new Promise(resolve => {
      this.debounceTimout = setTimeout(() => {
        this.upgradeService.validateBic({bic, iban})
          .subscribe(
            res => res.valid ? resolve(null) : resolve({bicInvalid: true}),
            err => resolve({bicInvalid: true}));
      }, 900);
    });
  }

}