import { Component, OnInit, OnChanges, Input} from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, ValidationErrors, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UpgradeService } from '@app/services/upgrade.service';

import { Observable, of, combineLatest, forkJoin, timer} from 'rxjs';
import { map, debounceTime, tap, filter, distinctUntilChanged, switchMap, concatMap, merge} from 'rxjs/operators';

import { Upgrade } from '@app/shared/models/upgrade';

@Component({
  selector: 'app-invoice-form',
  templateUrl: './invoice-form.component.html',
  styleUrls: ['./invoice-form.component.scss']
})
export class InvoiceFormComponent implements OnInit, OnChanges {

	public form: FormGroup;
	@Input() upgradeModel: Upgrade;

	public companyName = new FormControl('', Validators.required);
	public companyStreet = new FormControl('', Validators.required);
	public companyPostalCode = new FormControl('', Validators.required, this.postalCodeValidator());
	public companyCity = new FormControl('', Validators.required);
	public companyCountry = new FormControl('', Validators.required);
	public countrySelect = new FormControl('');
	public vatId = new FormControl('', Validators.maxLength(16) ,this.vatIdValidator());
	/**
	 * additional fields used for validation
	 */
	public companyStreetField = new FormControl('', [Validators.required, Validators.maxLength(40)]);
	public companyStreetNr = new FormControl('',	[Validators.required, Validators.maxLength(10)]);

	// clone fields needed for API
	public invoicingCompanyName  = new FormControl('');
	public invoicingCompanyStreet = new FormControl('');
	public invoicingCompanyZipCode = new FormControl('');
	public invoicingCompanyCity = new FormControl('');
	public invoicingCompanyCountry = new FormControl('');

	public countries = [
		'at', 'de', 'it', 'ch'
	];
	public euCountries = [
		'be','bg','cz','dk','ee','ie','el','es','fr','hr','cy','lv','lt','lu','hu','mt','nl','pl','pt','ro','si','sk','fi','se'
	];

  constructor(
  	private translate: TranslateService,
  	private upgradeService: UpgradeService,
  	private fb: FormBuilder
  ) {
  	this.form = this.fb.group({
			companyName: this.companyName,//['', Validators.required],
			companyStreet: this.companyStreet,
			companyStreetNr: this.companyStreetNr,
			companyStreetField: this.companyStreetField,
			companyPostalCode: this.companyPostalCode,
			companyCity: this.companyCity,
			companyCountry: this.companyCountry,
			countrySelect: this.countrySelect,
			vatId: this.vatId,
			// copy for api
			invoicingCompanyName: this.invoicingCompanyName,
			invoicingCompanyStreet: this.invoicingCompanyStreet,
			invoicingCompanyZipCode: this.invoicingCompanyZipCode,
			invoicingCompanyCity: this.invoicingCompanyCity,
			invoicingCompanyCountry: this.invoicingCompanyCountry
		});
  }

	ngOnChanges() {
		this.form.patchValue(this.upgradeModel);
		this.companyCountry.patchValue(this.countries[this.upgradeModel.siteId-1]);
    this.invoicingCompanyName.setValue(this.companyName.value);
	}

  ngOnInit(): void {
		// merge street number & copy to invoice company street
		combineLatest([
				this.companyStreetField.valueChanges,
				this.companyStreetNr.valueChanges
			]).subscribe(([street, nr])=> {
				let mergedCompanyStreet = `${street} ${nr}`;
				this.invoicingCompanyStreet.setValue(mergedCompanyStreet)
				this.companyStreet.setValue(mergedCompanyStreet);
			});

		// clone fields for api
		this.companyName.valueChanges.subscribe(val => this.invoicingCompanyName.setValue(val));
		this.companyCountry.valueChanges.subscribe(val => {
			this.invoicingCompanyCountry.setValue(val);
			this.companyPostalCode.updateValueAndValidity();
			this.vatId.updateValueAndValidity();
		})
		this.companyPostalCode.valueChanges.subscribe(val => this.invoicingCompanyZipCode.setValue(val))
		this.companyCity.valueChanges.subscribe(val => this.invoicingCompanyCity.setValue(val))
  }

  private postalCodeValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {

    	let country = control.root.get('companyCountry').value;
    	return timer(500).pipe(
    		distinctUntilChanged(),
    		switchMap(() => this.upgradeService.validatePostalCode({countryCode: country, postalCode: control.value}).pipe(
    			map(result => result.valid ? null : {invalidPostalCode: true})
    		))
    	);

    };
  }

  private vatIdValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
    	/*
    	 * this causes a bug in angular async form validation
    	 * though it is possible to use in the upper solutione
    	 * {@see} postalCodeValidator
    	 */
    	// if(!control.value || !control.get('companyCountry')) return of(null);

    	let country = this.companyCountry.value;// control.root.get('companyCountry').value;
    	if(!country || !control.value)
    		return of(null);
    	return timer(500).pipe(
    		distinctUntilChanged(),
    		switchMap(() => this.upgradeService.validateVat({countryCode: country, vatId: control.value}).pipe(
    			map(result => result.valid ? null : {invalidVatId: true})
    		))
    	);
    }
  }


}
