import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core'; 
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { StepperComponent } from './stepper/stepper.component';

import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';


import { ProductSelectorComponent } from './stepper/step-products/product-selector/product-selector.component';
import { AddonSelectorComponent } from './stepper/step-addons/addon-selector/addon-selector.component';

import { PostalCodeValidator } from './validators/postal-code.validator';
import { IbanValidator } from './validators/iban.validator';
import { BicValidator } from './validators/bic.validator';
import { VatValidator } from './validators/vat.validator';

import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ErrorComponent } from './pages/error/error.component';
import { SuccessComponent } from './pages/success/success.component';
import { UpgradeComponent } from './upgrade/upgrade.component';

import { MarkdownModule } from 'ngx-markdown';

import { StepInvoiceDataComponent } from './stepper/step-invoice-data/step-invoice-data.component';
import { StepCompleteOrderComponent } from './stepper/step-complete-order/step-complete-order.component';
import { PaymentSepaComponent } from './stepper/step-complete-order/payment-sepa/payment-sepa.component';
import { PaymentCcComponent } from './stepper/step-complete-order/payment-cc/payment-cc.component';
import { CartOrderComponent } from './stepper/step-complete-order/cart-order/cart-order.component';
import { InvoiceOrderComponent } from './stepper/step-complete-order/invoice-order/invoice-order.component';
import { ContactFormComponent } from './stepper/step-invoice-data/contact-form/contact-form.component';
import { InvoiceFormComponent } from './stepper/step-invoice-data/invoice-form/invoice-form.component';
import { StepProductsComponent } from './stepper/step-products/step-products.component';
import { StepAddonsComponent } from './stepper/step-addons/step-addons.component';

import { SharedModule } from "@app/shared/shared.module";
import { ErrorHandlerModule } from "@app/errors/error-handler.module";
import { MaterialModule } from "@app/shared/material.module";
import { StepSuccessComponent } from './stepper/step-success/step-success.component';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    StepperComponent,
    ProductSelectorComponent,
    AddonSelectorComponent,
    PostalCodeValidator,
    IbanValidator,
    BicValidator,
    VatValidator,
    ErrorComponent,
    SuccessComponent,
    UpgradeComponent,
    StepInvoiceDataComponent,
    StepCompleteOrderComponent,
    PaymentSepaComponent,
    PaymentCcComponent,
    CartOrderComponent,
    InvoiceOrderComponent,
    ContactFormComponent,
    InvoiceFormComponent,
    StepProductsComponent,
    StepAddonsComponent,
    StepSuccessComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,

    MaterialModule,
    // ErrorHandlerModule,
    //NoopAnimationsModule,
    HttpClientModule,
    // CoreModule,
    ErrorHandlerModule,
    SharedModule,
    MarkdownModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
