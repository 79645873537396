<div class="lead text-center mt-2 py-5">{{ 'stepper.summaryInfo' | translate }}</div>
<div class="row">
	<div class="col-md-6">
		<app-cart-order [cart]="cart" [isMonthly]="isMonthly" (moveStepper)="move($event)"></app-cart-order>
	</div>
	<div class="col-md-6">
		<app-invoice-order [invoiceModel]="invoiceModel" (moveStepper)="move($event)"></app-invoice-order>
	</div>
</div>

<div class="row">
	<div [ngClass]="isSepa ? 'col-md-4 text-center pl-0': 'col-md-8 pr-0 active'">
		<app-payment-cc (click)="setSepa(false)" [active]="!isSepa"></app-payment-cc>
	</div>
	<div [ngClass]="!isSepa ? 'col-md-4 text-center pr-0': 'col-md-8 pl-0 active'">
		<app-payment-sepa (click)="setSepa(true)" [active]="isSepa"></app-payment-sepa>
	</div>
</div>
<div class="row">
	<div class="col-md-12">
		<div class="contract mb-5 mt-3">
			<h5>{{ 'stepper.tosAgreement' | translate }}</h5>
			<div class="form-group" [formGroup]="form">
				<section class="tos-section">

					<div class="d-flex flex-row">
					  <div class="p-1">
					  	<mat-checkbox [formControl]="confirmedAgbRead" color="primary" required></mat-checkbox>
					  </div>
					  <div class="p-1" *ngIf="legal"><markdown [data]="legal[0].legal_checkbox_entry"></markdown></div>
					</div>
					<div class="d-flex flex-row">
					  <div class="p-1">
					  	<mat-checkbox [formControl]="confirmedPrivacyRead" color="primary" required></mat-checkbox>
					  </div>
					  <div class="p-1" *ngIf="legal"><markdown [data]="legal[1].legal_checkbox_entry"></markdown></div>
					</div>
					<div class="d-flex flex-row">
					  <div class="p-1">
					  	<mat-checkbox [formControl]="confirmedLegal" color="primary"  required></mat-checkbox>
					  </div>
					  <div class="p-1" *ngIf="legal">
							<span><markdown [data]="legal[2].legal_checkbox_entry"></markdown></span>
					  </div>
					</div>

				</section>
			</div>
		</div>
	</div>
</div>
<div class="d-flex justify-content-end">
	<button class="btn btn-secondary" mat-button matStepperPrevious>{{ 'stepper.previous' | translate }}</button>
	<button
		class="btn btn-primary ml-3"
		[disabled]="buttonDisabled"
		matStepperNext
		(click)="submitFlow()">
		{{ 'stepper.payNow' | translate }}
	</button>
</div>
