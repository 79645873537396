import { Component, OnInit, OnChanges, Input} from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { Upgrade } from '@app/shared/models/upgrade';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnChanges, OnInit {

	public form: FormGroup;
	@Input() upgradeModel: Upgrade;

	public mainContactSalutation = new FormControl('', Validators.required);
	public mainContactTitle = new FormControl('');
	public mainContactFirstName = new FormControl('', Validators.required);
	public mainContactLastName = new FormControl('', Validators.required);
	public eInvoiceEmailAddress = new FormControl('', Validators.required);
	public mainContactPhoneNumber = new FormControl('', Validators.pattern('^\\+[1-9]\\d{10,14}$'));
	public mainContactEmailAddress = new FormControl(''); // hidden field needed for API

  constructor(
  	private translate: TranslateService,
  	private fb: FormBuilder
  ) {

  	this.form = this.fb.group({
			mainContactSalutation: this.mainContactSalutation,
			mainContactTitle: this.mainContactTitle,
			mainContactFirstName: this.mainContactFirstName,
			mainContactLastName: this.mainContactLastName,
			eInvoiceEmailAddress: this.eInvoiceEmailAddress,
			mainContactEmailAddress: this.mainContactEmailAddress,
			mainContactPhoneNumber: this.mainContactPhoneNumber
		});

  }
	ngOnChanges() {
		this.form.patchValue(this.upgradeModel);
	}
  ngOnInit(): void {
  	this.eInvoiceEmailAddress.valueChanges.subscribe(
  		val => this.mainContactEmailAddress.setValue(val));
  }

}
