import { Directive, forwardRef, Attribute } from "@angular/core";
import { NG_ASYNC_VALIDATORS, Validator, AbstractControl } from "@angular/forms";
import { Observable } from "rxjs";
import { UpgradeService } from '../services/upgrade.service';

@Directive({
  selector: "[vatValidator][formControlName], [vatValidator][formControl], [vatValidator][ngModel]",
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => VatValidator), multi: true
    }
  ]
})

export class VatValidator implements Validator {

	constructor(
		@Attribute ('vatValidator') public vatId: string,
		@Attribute ('vatCountryMatch') public vatCountryMatch: string,
		private upgradeService: UpgradeService
	) {}
	
	private debounceTimout;

  validate( vat : AbstractControl ) : Promise<{[key : string] : any}>|Observable<{[key : string] : any}> {

    clearTimeout(this.debounceTimout);

		let countryControl = vat.root.get('companyCountry');

		// improve name directive from attribute
		// eg. vatCountryMatch="countrySelect"
		// console.log(vat.root.get('companyName'));
		let vatIdValue = vat.value;

		// if UID input before country is set
		// if(this.isVatCountryMatch && countryControl.value && vat.root.get('vatId').value) {
		// 	let countryValue = countryControl.value.alpha2Code.toLowerCase();
		// 	return this.validateVatWithCountry(vatIdValue,countryValue);
		// }
  	// if is vat field and country is selected
  	if(!this.isVatCountryMatch && countryControl && vatIdValue) {
		// if(countryControl && vatIdValue) {
			if(countryControl.value){
				let countryValue = countryControl.value //.alpha2Code.toLowerCase();
				return this.validateVatWithCountry(vatIdValue,countryValue);
			}
			else{
				return new Promise(resolve => resolve(null));
			}
		} else {
  		return new Promise(resolve => resolve(null));
		}
  }

  private get isVatCountryMatch() {
      if (!this.vatCountryMatch) return false;
      return this.vatCountryMatch === 'true' ? true: false;
  }

  validateVatWithCountry( vatId: string, countryCode: string ) {
		    return new Promise((resolve, reject) => {
	        this.debounceTimout = setTimeout(() => {
            this.upgradeService.validateVat({ countryCode, vatId})
              .subscribe(
                res => res.valid ? resolve(null) : resolve({vatIdCountryMismatch: true}),
                err => resolve({vat: true}));
	        }, 600);
		    });
  }

}