

export class Upgrade {


	constructor(
		public accountBic?: '',
		public accountIban?: '',
		public bankAccept?: false,
		public bankDataLater?: true,
		public commercialContactFirstName?: string,
		public commercialContactLastName?: string,
		public commercialContactSalutation?: string,
		public commercialContactTitle?: string,
		public companyCity?: string,
		public companyCountry?: string,
		public companyName?: string,
		public companyPostalCode?: string,
		public companySectorIndustryId?: number,
		public companySizeId?: number,
		public companyStreet?: string,
		public companyUseCase?: string,
		public companyUseCaseId?: number,
		public confirmedAgbRead?: boolean,
		public customerStatus?: string,
		public diallingCodeId?: number,
		public eInvoiceEmailAddress?: string,
		public invoicingCompanyCity?: string,
		public invoicingCompanyCountry?: string,
		public invoicingCompanyName?: string,
		public invoicingCompanyStreet?: string,
		public invoicingCompanyZipCode?: string,
		public links?: any,
		public mainContactEmailAddress?: string,
		public mainContactFirstName?: string,
		public mainContactLastName?: string,
		public mainContactPhoneNumber?: string,
		public mainContactSalutation?: string,
		public mainContactTitle?: string,
		public newsletters?: boolean,
		public shoppingCart?:  any,
		public siteId?: number,
		public vatId?: string,
		public voucherCode?: string
	) { }

}