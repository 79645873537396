import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

import { Observable, of, Subscription } from 'rxjs'

import { catchError, map, tap, take, switchMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Account } from '@app/shared/interfaces';
import { Upgrade } from '@app/shared/models/upgrade';
import { CcAuthorize, CcRetrieve, CcOrder } from '@app/shared/interfaces';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UpgradeService {

  private apiUrl = environment.websmsApiUrl;

  constructor(private http: HttpClient) { }

  validatePostalCode(request: {countryCode: string, postalCode: string}): Observable<any> {
    return this.http.post(this.apiUrl + 'validators/validate_postal_code', request);
  }

  validateIban(request: { iban: string }): Observable<any> {
    return this.http.post(this.apiUrl + 'validators/validate_iban', request );
  }

  validateBic(request: { bic: string, iban: string } ): Observable<any> {
    return this.http.post(this.apiUrl + 'validators/validate_bic', request);
  }

  validateVat(request: { countryCode: string, vatId: string }): Observable<any> {
    return this.http.post(this.apiUrl + 'validators/validate_vat_id', request);
  }

  getVerifyAccount(): Observable<Account>  {
    return this.http.get<Account>(this.apiUrl + 'account');
  }

  getVerifyUpgrade(upgradeUrl: string): Observable<Upgrade> {
    return this.http.get<Upgrade>(upgradeUrl);
  }

  prepareUpgrade(request: Upgrade) {
    return {
      ...request, ...{
        /**
         *
         * Subscribe to component inner values
         *  use patch values to combine properties
         */
        // bankDataLater: true,
        // confirmedAgbRead: true
      }
    };
  }

  putUpgradeFinal(request: Upgrade, userUpgradeUrl:string) {
    return this.http.put<Upgrade>(userUpgradeUrl, this.prepareUpgrade(request), {observe: 'response'}).pipe(
      tap(res => {console.log('Upgrade Put:', res)})
    );
  }
  // Payment Sepa Flow
  putSepaPaymentMethod(): Observable<any> {
    return this.http.put(this.apiUrl + 'customer/contract_payment', {paymentMethod: 'sepa_core'});
  }

  putSepaContract(request: { accountIban: string, accountBic: string }):Observable<any> {
    return this.http.put(this.apiUrl + 'customer/contract_payment/sepa', request);
  }

  finalizeSepaFlow(upgrade: Upgrade, userUpgradeUrl: string) {
    return this.putUpgradeFinal(upgrade, userUpgradeUrl).pipe(
      switchMap(() => this.putSepaContract({
        accountIban: upgrade.accountIban,
        accountBic: upgrade.accountBic
      })),
      switchMap(() => this.putSepaPaymentMethod())
    )
  }

  // Payment CC Flow
  getOrderIdCc(): Observable<CcOrder> {
    return this.http.post<CcOrder>(this.apiUrl + 'customer/contract_payment/cc/order', {});
  }

/**
 *
 * Authorize request with Saferpay token to API
 * @param request
 * @returns CcAuthorize
 */
  getCcAuthorize(request: {orderId: string, saferpayFieldsToken: string, siteId:number}): Observable<CcAuthorize> {
    return this.http.post<CcAuthorize>(this.apiUrl + 'payments/cc/authorize',   {
      orderId:  request.orderId,
      saferpayFieldsToken:  request.saferpayFieldsToken,
      siteId: request.siteId,
      payload: '',
      // default
      cancelRedirectUrl:  environment.redirectUrl + 'cancel?threeDs=cancel',
      successRedirectUrl: environment.redirectUrl + 'success?threeDs=true',
      failRedirectUrl:    environment.redirectUrl + 'error?threeDs=error',
      provider: 'saferpay',
      attempThreeD: true,
      spaForward:true,
      flow:'contract_upgrade',
    });
  }

/**
 *
 * Getting payment details after redirect from saferpay
 *
 */
  postCcRetrieve(request: {token: string, nonce: string}): Observable<CcRetrieve> {
    return this.http.post<CcRetrieve>(this.apiUrl + 'payments/cc/retrieve', request);
  }

/**
 *
 * Final call of CC-flow to update the contract
 *
 */
  putCcPaymentMethod(): Observable<any> {
    return this.http.put(this.apiUrl + 'customer/contract_payment', {paymentMethod: 'cc', valid:'true'});
  }

  finalizeCcFlow(upgrade, userUpgradeUrl, saferpayToken, siteId) {

    return this.getOrderIdCc().pipe(
      switchMap(orderCc => this.putUpgradeFinal(upgrade, userUpgradeUrl).pipe(
        map(() => orderCc))
      ),
      switchMap(orderCc => this.getCcAuthorize({
        orderId: orderCc.orderId,
        saferpayFieldsToken: saferpayToken,
        siteId: siteId
      }))
    );
// map((ccAuthorize)=> {
      //   localStorage.setItem('cc_nonce', ccAuthorize.nonce);
      //   if(ccAuthorize.redirectRequired)
      //     return true;
      //   return false;
      // })
  }


}
