<section class="cart mb-5">
	<h5>{{ 'stepper.cart' | translate }} <a class="ml-auto icon-edit" (click)="move(0)"></a></h5>

	<div class="packages">
		<h6 class="mt-3 text-primary">{{ 'stepper.smsPackage' | translate }}</h6>

		<div class="d-flex mb-1" *ngFor="let package of cart?.packages">
			<div class="">{{package?.displayName}}</div>
			<div class="ml-auto pr-5">
				<span class="currency">{{ 'stepper.currency' | translate }}</span>
			{{ isMonthly ? package.priceMonthly : package.priceYearly }}</div>
		</div>

	</div>

	<div class="addons" *ngIf="cart?.addons?.length">
		<h6 class="mt-3 text-primary">{{ 'stepper.addons' | translate }}</h6>
		<div class="d-flex mb-1" *ngFor="let addon of cart?.addons">
			<div class="">{{addon?.displayName}}</div>
			<div class="ml-auto pr-5">

				<div *ngIf="addon.isDisabled else elseBlock">{{ 'stepper.included' | translate }}</div>
				<ng-template #elseBlock>
					<span class="currency">{{ 'stepper.currency' | translate }}</span>
					{{ isMonthly ? addon.priceMonthly : addon.priceYearly }}
				</ng-template>

			</div>
		</div>
	</div>

	<div class="sum" *ngIf="cart.packages">
		<hr>
		<div class="d-flex mb-1">
			<div class="">
				{{ 'stepper.totalAmount' | translate }}<br>

				<small *ngIf="isMonthly else subscriptionBlock">
					{{ 'stepper.subscriptionMonthly' | translate }}
				</small>
				<ng-template #subscriptionBlock>
					<small>{{ 'stepper.subscriptionYearly' | translate }}</small>
				</ng-template>

			</div>
			<div class="ml-auto pr-4">
				<span class="currency">{{ 'stepper.currency' | translate }}</span>
				{{ isMonthly ? sumMonthly() : sumYearly() }},00
			</div>
		</div>
	</div>

</section>