import { Component, OnInit, Input } from '@angular/core';
import { ParentCallbackMessage } from '@app/shared/interfaces';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-step-success',
  templateUrl: './step-success.component.html',
  styleUrls: ['./step-success.component.scss']
})
export class StepSuccessComponent implements OnInit {

	@Input() success: any;

  constructor() { }

  ngOnInit(): void {
  }

  sendSuccess() {
  	if ( window.location !== window.parent.location ) {
      let message: ParentCallbackMessage = {
        action: 'complete',
      }
      parent.postMessage(message, environment.tokenAllowedOrigin);
    } else {
      // not in iframe
      window.location.href = environment.tokenAllowedOrigin;
    }
  }

}
