<div class="payment-form credit-card-form" [class.active]="active">
	<div class="card mb-3 p-2 shadow-sm" [class.shadow]="active">
		
		<div class="payment-header" [class.m-auto]="!active">
<!-- 			<div class="align-middle payment-method-icon">
				<img src="/assets/icons/cc-icon.png" />
			</div> -->
<!-- 			<mat-icon color="primary" class="align-middle payment-method-icon" aria-hidden="false" aria-label="Credit card icon">
				credit_card
			</mat-icon> -->
			<img class="payment-method-icon-fa p-2" src="./assets/icons/credit-card.svg" alt="credit-card" />
			<h5 class="align-middle">{{ 'invoiceDetails.paymentCreditCard' | translate }}</h5>
			<div class="payment-icons align-middle mb-4">
				<div class="payment-icon visa"></div>
				<div class="payment-icon mastercard"></div>
			</div>
		</div>

		<div class="cc-inputs mt-2 p-2 safer-pay" [class.d-none]="!active">

			<div class="container" id="safer-pay-form">
			  <div class="row py-1">
					<input type="text" class="safer-pay-input form-control col-12" id="fields-holder-name" readonly />
			  </div> 
			  <div class="row py-1">
			    <input class="safer-pay-input form-control col-12" id="fields-card-number" readonly />
			  </div>
			  <div class="row py-1">
			    	<input class="safer-pay-input form-control col-4" id="fields-expiration" style="margin-right:8px !important" readonly />
			    	<input class="safer-pay-input form-control col-3"  id="fields-cvc" readonly />
			  </div>
			</div>
			<div class="safer-pay-service-icon">
				<span>provided by</span><img src="./assets/icons/safer-pay-service.svg">
			</div>
			<div class="cc-info row" [formGroup]="form">
			  <div class="col-md-12">
			    <input [formControl]="saferpayToken" class="form-control" id="token" type="hidden" readonly="" />
			  </div>
				<div class="col-md-1 pt-2">
					<mat-checkbox [formControl]="confirmedCcRead" color="primary"></mat-checkbox>
				</div>

				<div class="col-md-11 pt-2 cc-agreement">{{ 'invoiceDetails.creditCardAgreement' | translate}}</div>
			</div>

		</div>

	</div>
</div>