import { Component, OnInit } from '@angular/core';
import { ParentCallbackMessage } from '@app/shared/interfaces';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  sendMessage() {
    if ( window.location !== window.parent.location ) {
      let message: ParentCallbackMessage = {
        action: 'error',
      }
      parent.postMessage(message, environment.tokenAllowedOrigin);
    } else {
      // not in iframe
      window.location.href = environment.tokenAllowedOrigin;
    }
  }
}
