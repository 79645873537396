import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-step-addons',
  templateUrl: './step-addons.component.html',
  styleUrls: ['./step-addons.component.scss']
})
export class StepAddonsComponent implements OnInit {
	
	public isCompleted = false;
	public form: FormGroup;
	@Input() public addons: any;
	@Input() public isMonthly: boolean;

	public addonPackages = new FormControl('');

  constructor(
  	private translate: TranslateService,
  	private fb: FormBuilder
  ) { 
  	this.form = this.fb.group({
  		shoppingCart: this.fb.group({
  			addonPackages: this.addonPackages
  		})
		});
  }

  ngOnInit(): void {
  }

}
