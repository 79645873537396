import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-payment-sepa',
  templateUrl: './payment-sepa.component.html',
  styleUrls: ['./payment-sepa.component.scss']
})
export class PaymentSepaComponent implements OnInit {

	@Input() public active: boolean;
	// add sepa reactive form
  public form: FormGroup;

  public mainSepa = new FormControl('');
  public accountIban = new FormControl('');
  public accountBic = new FormControl('');

  constructor(
    private translate: TranslateService,
    private fb: FormBuilder) {
  	this.active = false;

    this.form = this.fb.group({
      mainSepa: this.mainSepa,
      accountIban: this.accountIban,
      accountBic: this.accountBic
    });
  }

  ngOnInit(): void {
  }

}
