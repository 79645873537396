import { Component, OnInit, Input, Inject, AfterViewInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';

import { SaferPayService } from '../../../services/safer-pay.service';
import { DOCUMENT } from '@angular/common'; 
import { from } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

declare let SaferpayFields: any;

@Component({
  selector: 'app-payment-cc',
  templateUrl: './payment-cc.component.html',
  styleUrls: ['./payment-cc.component.scss']
})
export class PaymentCcComponent implements AfterViewInit {

	@Input() public active:boolean;
	public saferPayInit = false;

	public form: FormGroup;
	
  public confirmedCcRead = new FormControl('', Validators.requiredTrue);
  public saferpayToken = new FormControl('', Validators.required);

  /*
  * 	Implementing simple async validation for saferpay,
  *		since there is no 'all validated' event
  */
  private mapValidation: { [key: string]: boolean; } = {
  	'holdername': false,
  	'cardnumber':	false,
  	'expiration': false,
  	'cvc':false
  };
  private isSubmitted: boolean = false;

  constructor(
  	@Inject(DOCUMENT) document,
  	private translate: TranslateService,
  	private saferPayService: SaferPayService,
  	private fb: FormBuilder
  ) {	
  	this.active = true;
  	this.form = this.fb.group({
			saferpayToken: this.saferpayToken,
			confirmedCcRead: this.confirmedCcRead,
		});
  }

  ngAfterViewInit(): void {
		// wait until script loaded
		from(this.saferPayService.load()).subscribe(
			() => this.initSaferPay()
		);
  }
  
  checkValidationState() {
  	return Object.keys(this.mapValidation).every((k) => this.mapValidation[k])
  }

  checkSubmitState() {
  	if(this.checkValidationState() && !this.isSubmitted) {
  		this.isSubmitted = true;
  		from(this.submitSaferPay()).subscribe(evt => {
  			this.saferpayToken.setValue(evt.token);
  		})//.then((evt) => this.form.patchValue({saferpayToken: evt.success.token}));
  	}
  }

  public submitSaferPay(): Promise<any> {
  	// should be a promise
  	console.log('submit safer-pay');
  	return new Promise(resolve => {
		  SaferpayFields.submit({
		    onSuccess: (evt) => {
		    	// this.form.patchValue({saferpayToken: evt.token}, {emitEvent: true});
		    	console.log(this.form.value);
		    	resolve({token:evt.token});
		    	// resolve error
		    },
		    onError: (evt)  => {
		    	// resolve error
		    	resolve({success:false, error: evt.message});
		      console.error('Error in submit: ' + evt.message);
		    }
		  });
  	});

  }

  public initSaferPay() {
		SaferpayFields.init({
		  apiKey: this.saferPayService.apiKey,
		  url: this.saferPayService.apiUrl,
		  placeholders: {
		    holdername: 'Karteninhaber',//this.translate.get('HOME.TITLE'),
		    cardnumber: 'Kartennummer',
		    expiration: 'MM/JJJJ',
		    cvc: 'CVC'
		  },
		  style: {
      '.form-control': 'width: 100%; ' +
          'border-radius: 4px; ' +
          'border: 1px solid #f4f4f5; ' +
          'box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12); ' +
          'user-select: text; ' +
          'margin: 0; ' +
          'font-family: "London",Verdana, Arial, Helvetica, sans-serif, "London Light";',
      '.form-control:hover': 'box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.07); ' +
          'transition: all 200ms ease-in; ' +
          'border: 1px solid #e6e6e6;',
      '#fields-expiration': 'margin-right: 8px',
      '.form-control:focus': 'outline: none; ' +
          'border: 1px solid #55d391;',
      '.form-control:is-invalid': 'border: 1px solid rgba(253, 99, 105, 0.54) !important; ' +
          'box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);'

		  },
		  onSuccess: () => {
		  	this.saferPayInit = true;
		  },
		  onError: (evt) => {
		  	// error is not in ng zone, throwing error crashes whole app;
		    // throw new Error('Error in safer-pay init: ' + evt.message);
		  },
		  onValidated: (evt) => {
		  	this.mapValidation[evt.fieldType] = evt.isValid;
		  	this.checkSubmitState();
		  }
		});
  }

}
