<div class="payment-form sepa-form" [class.active]="active">
	<div class="card mb-3 p-2 shadow-sm" [class.shadow]="active">

		<div class="payment-header" [class.m-auto]="!active">
<!-- 			<mat-icon color="primary" class="align-middle payment-method-icon" aria-hidden="false" aria-label="Credit card icon">
				receipt_long
			</mat-icon> -->
			<img class="payment-method-icon-fa sepa p-2" src="./assets/icons/file-invoice.svg" alt="credit-card" />
			<h5 class="align-middle">{{ 'invoiceDetails.paymentSepa' | translate}}</h5>
			<div class="payment-icons align-middle mb-4">
				<div class="payment-icon sepa"></div>
			</div>
		</div>

		<div class="sepa-inputs mt-3 p-2" [class.d-none]="!active" [formGroup]="form">

			<mat-form-field class="full-width py-2" appearance="outline">
				<mat-label>{{ 'invoiceDetails.sepaAccountHolder' | translate}}</mat-label>
				<input type="text" matInput [formControl]="mainSepa" [required]="active">
				<mat-error class="pt-2" *ngIf="mainSepa.invalid">{{ 'errors.forms.required' | translate }}</mat-error>
			</mat-form-field>

			<mat-form-field class="full-width py-2" appearance="outline">
				<mat-label>{{ 'invoiceDetails.sepaAccountIban' | translate}}</mat-label>
				<input type="text" matInput class="" [formControl]="accountIban" ibanValidator [required]="active">
				<mat-error class="pt-2" *ngIf="accountIban.invalid">{{ 'errors.forms.iban' | translate }}</mat-error>
			</mat-form-field>

			<mat-form-field class="full-width py-2" appearance="outline">
				<mat-label>{{ 'invoiceDetails.sepaAccountBic' | translate}}:</mat-label>
				<input type="text" matInput class="" [formControl]="accountBic" bicValidator [required]="active">
				<mat-error class="pt-2" *ngIf="accountBic.invalid">{{ 'errors.forms.bic' | translate }}</mat-error>
			</mat-form-field>

		</div>

	</div>

</div>