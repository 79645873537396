import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Cart } from '@app/shared/models/cart';

@Component({
  selector: 'app-cart-order',
  templateUrl: './cart-order.component.html',
  styleUrls: ['./cart-order.component.scss']
})
export class CartOrderComponent implements OnInit {

	@Input() cart: Cart;
	@Input() isMonthly:Boolean;

  @Output() moveStepper = new EventEmitter<any>();

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }

  sumMonthly():number {
  	return this.cart.getSumMonthly();
  }

  sumYearly():number {
  	return this.cart.getSumYearly();
  }

  move(stepIndex?: number) {
  	this.moveStepper.emit(0);
  }

}
