import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validator, NG_VALIDATORS } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { Product } from '@app/shared/interfaces';

@Component({
  selector: 'app-product-selector',
  templateUrl: './product-selector.component.html',
  styleUrls: ['./product-selector.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: ProductSelectorComponent, multi: true },
  ]
})

export class ProductSelectorComponent implements ControlValueAccessor {

	constructor(private modalService: NgbModal) {}

	@Input() products: Product[] = [];
  @Input() isMonthly: Boolean = false;

  selectedProduct: Product;

  private onChange: (value: String) => void;

  selectProduct(value: Product) {
    this.selectedProduct = value;
    this.onChange(value.systemIdentifier);
  }

  get value() {
  	return this.selectedProduct.systemIdentifier;
  }

  writeValue(value: Product) {
    this.selectedProduct = value;
  }

  registerOnChange(onChange: (value: String) => void) {
    this.onChange = onChange;
  }

  registerOnTouched() {}

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'xl' });
  }

  // private getDismissReason(reason: any): string {

  // }

}
