import { Product } from '@app/shared/interfaces';
import { Addon } from '@app/shared/interfaces';

export class Cart {

	public packages: Product[];
	public addons: Addon[];

	constructor(

	) { }
	public addProduct(product: Product){
		// implement .push() for multiple
		this.packages = [product];
	}

	private sumPackages(paymentPlan) {
		return this.packages.reduce((a,b)=>  a + parseInt(b[paymentPlan]), 0)
	}

	private sumAddons(paymentPlan) {
		return this.addons.filter(a => !a.isDisabled).reduce((a,b)=>  a + parseInt(b[paymentPlan]), 0);
	}
	
	public getSumMonthly(): number {
		let paymentPlan = 'priceMonthly'
		return +this.sumAddons(paymentPlan) + +this.sumPackages(paymentPlan);
	}

	public getSumYearly() {
		let paymentPlan = 'priceYearly'
		return +this.sumAddons(paymentPlan) + +this.sumPackages(paymentPlan);
	}

}