<mat-horizontal-stepper 
  [linear]="isLinear" 
  labelPosition="bottom" 
  ngClass="{{ 'last-edited-step-' + stepper.selectedIndex }}" 
  (selectionChange)="stepChanged($event)"
  #stepper>

  <mat-step [editable]="isEditable" [stepControl]="stepProducts">
    <ng-template matStepLabel>{{ 'stepper.packages' | translate }}</ng-template>
    <app-step-products [products]="products$" [isMonthly]="isMonthly" (changePayment)="isMonthly = $event"></app-step-products>
  </mat-step>

  <mat-step [editable]="isEditable">
    <ng-template matStepLabel>{{ 'stepper.addons' | translate }}</ng-template>
    <app-step-addons [addons]="addons$" [isMonthly]="isMonthly"></app-step-addons>
  </mat-step>

  <mat-step [editable]="isEditable" [completed]="stepInvoiceControl">
    <ng-template matStepLabel>{{ 'invoiceDetails.invoiceAndContact' | translate }}</ng-template>
    <app-step-invoice-data [upgradeModel]="upgrade"></app-step-invoice-data>
  </mat-step>

  <mat-step [editable]="isEditable" [completed]="stepCompleteOrder">
    <ng-template matStepLabel>{{ 'stepper.completeOrder' | translate }}</ng-template>
    <app-step-complete-order 
      [invoiceModel]="stepInvoiceData"
      [cart]="cart"
      [isMonthly]="isMonthly"
      [legal]="legal$"
      (paymentFlow)="submitFlow($event)"
      (moveStepper)="moveStepper($event)"
    ></app-step-complete-order>
  </mat-step>

  <mat-step [editable]="isEditable">
    <ng-template matStepLabel>{{ 'stepper.success' | translate }}</ng-template>
    <app-step-success 
    ></app-step-success>
  </mat-step>

</mat-horizontal-stepper>