import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { timer } from 'rxjs';
import { catchError, mergeMap, map} from 'rxjs/operators';

import { MatStepper } from '@angular/material/stepper';

import { UpgradeService } from '@app/services/upgrade.service';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { StoryblokService } from '@app/services/storyblok.service';
import { TranslateService } from '@ngx-translate/core';

import { Product } from '@app/shared/interfaces';
import { Addon } from '@app/shared/interfaces';
import { Account } from '@app/shared/interfaces';
import { Upgrade } from '@app/shared/models/upgrade';
import { Cart } from '@app/shared/models/cart';

//Steps
import { StepCompleteOrderComponent } from './step-complete-order/step-complete-order.component';
import { StepInvoiceDataComponent } from './step-invoice-data/step-invoice-data.component';
import { StepProductsComponent } from './step-products/step-products.component';
import { StepAddonsComponent } from './step-addons/step-addons.component';

import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit, AfterViewInit{

  public account: Account;
  private userUpgradeUrl: string;

  public loading: boolean = true;

  // Model of the whole FormRequest
  public upgrade =  new Upgrade();

  // payment method two way binding
  public isMonthly = false;

  // Storyblok contents;
  public products$: Product[];
  public addons$: Addon[];
  public legal$: any;
  public success$: any;

  /**
   * Todo: move to cart service
   * Update in child forms
   */
  public cart = new Cart();

  /**
   * Todo: move to upgrade service
   */
  private siteIds = ['', 'at', 'de','ch', 'it'];
  private supportedLanguages = ['en', 'de'];

  /**
   * Stepper config
   */
  @ViewChild('stepper') stepper: MatStepper;
  isLinear: boolean = true;
  isEditable: boolean = true;

  // Steps
  @ViewChild(StepCompleteOrderComponent) stepCompleteOrderComponent: StepCompleteOrderComponent;
  @ViewChild(StepInvoiceDataComponent) stepInvoiceDataComponent: StepInvoiceDataComponent;
  @ViewChild(StepProductsComponent) stepProductsComponent: StepProductsComponent;
  @ViewChild(StepAddonsComponent) stepAddonsComponent: StepAddonsComponent;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private upgradeService: UpgradeService,
    private storyblokService: StoryblokService,
    private gaService: GoogleAnalyticsService,
    private cdr:ChangeDetectorRef
  ) { }

  get stepCompleteOrder() {
    return this.stepCompleteOrderComponent ? this.stepCompleteOrderComponent.isCompleted : null;
  }
  get stepInvoiceControl() {
    return this.stepInvoiceDataComponent ? this.stepInvoiceDataComponent.isCompleted : null;
  }
  get stepInvoiceData() {
    return this.stepInvoiceDataComponent ? this.stepInvoiceDataComponent.form : null;
  }
  get stepProducts() {
    return this.stepProductsComponent ? this.stepProductsComponent.form : null;
  }

  ngOnInit() {
    this.initUpgrade();
  }

  /**
   *
   * Verifies the account of the given token.
   * Loads content (products, addons) for Websms shopping cart.
   * Loads content for legal & success page.
   */
  initUpgrade() {
    // switchmap rxjs and then merge inner observables
    this.upgradeService.getVerifyAccount().subscribe( account => {
      this.account = account;
      if(this.account.customer.trial) {

        this.userUpgradeUrl = this.account.customer.links.find(link => link.rel === 'upgrade').href;
        this.upgradeService.getVerifyUpgrade(this.userUpgradeUrl).subscribe(
          upgrade => this.upgrade = upgrade
        );

        // settings for storyblok api call
        let locale = this.account.locale.match(/en|de/) ? this.account.locale : 'en';
        let country = this.siteIds[this.account.customer.siteId];

        // fallback for swiss translation (currency CHF)
        if(country == 'ch') {
          this.translate.use('ch');
        } else {
          this.translate.use(locale);
        }

        this.gaService.setUserIds(this.account, country);
        this.gaService.initCustomer();

        this.storyblokService.getUpgrade(`${locale}-${country}`).subscribe(
          stories => {
            this.addons$ = stories.addons;
            this.products$ = stories.products;
            this.success$ = stories.success;
            this.legal$ = stories.legal;
            this.loading = false;
          }
        );
      } else {
        this.loading = false;
        throw new Error('User is not trial.')
      }
    });
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();

    /**
     * Todo: move to cart service
     */
    this.stepProducts
      .get('shoppingCart.basePackage').valueChanges
      .subscribe(val => {
        let product = this.products$.find(product => product.systemIdentifier == val);
        this.cart.addProduct(product);
        this.updateFeatures(product);
      });

    this.stepAddonsComponent.form.get('shoppingCart.addonPackages').valueChanges.subscribe(
      _ => this.cart.addons = this.addons$.filter(x => x.isSelected) );
  }

  /**
   * Todo: should be in Cart Service and child step forms
   */
  updateFeatures(product){
    // update feature selection
    this.addons$.forEach(addon =>
      addon.isSelected = addon.isDisabled = product.preselectedFeatures.includes(addon.systemIdentifier))

    this.stepAddonsComponent.form.get('shoppingCart.addonPackages').patchValue([]);
    // update cart addons
    this.cart.addons = this.addons$.filter(x => x.isSelected);
  }

  submitFlow(paymentFlow: {flow: string, payload: any }) {
    if(paymentFlow.flow == 'sepa') {
      this.submitSepaFlow(paymentFlow.payload);
    }
    if(paymentFlow.flow == 'cc') {
      this.submitCcFlow(paymentFlow.payload);
    }
  }

  /**
   * TODO: add this method to upgrade service
   * upgrade service should listen to all form changes and merge them automatically
   */
  prepareUpgrade() {
    return {
      ...this.stepInvoiceData,
      shoppingCart: {
        ...this.stepProducts.get('shoppingCart').value,
        ...this.stepAddonsComponent.form.get('shoppingCart').value
      },
      confirmedAgbRead: true,
      bankDataLater: true,
      // invoicingCompanyName: this.upgrade.companyName
    } as Upgrade;
  }

  /**
   * finalize SEPA submission
   */
  submitSepaFlow(sepaDetails) {
    this.upgradeService.finalizeSepaFlow({...this.prepareUpgrade(), ...sepaDetails}, this.userUpgradeUrl).subscribe(
      res => {
        this.gaService.upgradeConversion(this.cart, this.isMonthly);
        this.successStep();
    })

  }

  /**
   * Todo: transfer functionality to upgrade service to keep code cleaner in stepper
   * use rxjs concat to make nice observables and get rid of async function
   */
   submitCcFlow(ccDetails) {
    this.upgradeService.finalizeCcFlow(
      this.prepareUpgrade(),
      this.userUpgradeUrl,
      ccDetails.saferpayToken,
      this.account.customer.siteId
    ).subscribe(ccAuthorize => {
      console.log('cc flow final', ccAuthorize);
      /**
       * Remember nonce, redirect if necessary
       */
      localStorage.setItem('cc_nonce', ccAuthorize.nonce);
      if(ccAuthorize.redirectRequired){
        // show loading message
        this.gaService.upgradeConversion(this.cart, this.isMonthly);
        window.location.href = ccAuthorize.paymentRedirectUrl;
      } else {
        // set completed order and move to success step
        this.upgradeService.putCcPaymentMethod().subscribe(
          () => this.successStep()
        )
      }
    });
   }


  moveStepper(index: number) {
    this.stepper.selectedIndex = index;
  }

  successStep(){
    this.isLinear = false;
    this.stepCompleteOrderComponent.setCompleted();
    /* workaround for stepper */
    timer(50).subscribe(() => {
      this.stepper.next();
      this.isEditable = false;
    });
  }

  /**
   * Custom google analytics tracking of steps
   */
  stepChanged(evt: any) {
    this.gaService.stepperChange(evt.selectedIndex);
  }
}
