import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	constructor(public authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!request.url.includes(environment.websmsApiUrl)) {
      return next.handle(request);
    }
    if(environment.authHeader) {
      request = request.clone({ 
        setHeaders: {
          'X-Auth-Key': environment.authHeader,
          'Authorization': `Bearer ${this.authService.getToken()}`
        }
      })
    } else {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.getToken()}`
        }
      }); 
    }

    return next.handle(request);
  }
}
