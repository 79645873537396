import { Directive, forwardRef } from "@angular/core";
import { NG_ASYNC_VALIDATORS, Validator, AbstractControl } from "@angular/forms";
import { Observable } from "rxjs";
import { UpgradeService } from '../services/upgrade.service';

@Directive({
  selector: "[postalCodeValidator][formControlName], [postalCodeValidator][ngModel]",
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => PostalCodeValidator), multi: true
    }
  ]
})

export class PostalCodeValidator implements Validator {

	constructor(private upgradeService: UpgradeService) {}

  private debounceTimout;

  validate( c : AbstractControl ) : Promise<{[key : string] : any}>|Observable<{[key : string] : any}> {
    clearTimeout(this.debounceTimout);
    let country = c.root.get('companyCountry').value;
    if(country) {
    	return this.validatePostalCode(country, c.value ); //.alpha2Code.toLowerCase()
    }
    else { 
    	return new Promise(resolve => resolve(null))
    }
  }

  validatePostalCode( countryCode: string, postalCode : string ) {
    return new Promise(resolve => {
      this.debounceTimout = setTimeout(() => {
        this.upgradeService.validatePostalCode({countryCode, postalCode})
          .subscribe(
            res => res.valid ? resolve(null) : resolve({postalCodeInvalid: true}),
            err => resolve({postalCodeInvalid: true}));
      }, 900);
    });
  }

}

// import { of } from 'rxjs';

// import { Directive, forwardRef } from "@angular/core";
// import { NG_ASYNC_VALIDATORS, AbstractControl, AsyncValidator, ValidationErrors } from "@angular/forms";
// import { Observable } from "rxjs";
// import { UpgradeService } from '../services/upgrade.service';


// @Directive({
//   selector: '[postalCodeAsyncValidator]',
//   providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: PostalCodeAsyncValidatorDirective, multi:
// true}]
// })
// class PostalCodeAsyncValidatorDirective implements AsyncValidator {

// 	constructor(private upgradeService: UpgradeService) {}

//   validate(control: AbstractControl): Observable<ValidationErrors|null> {
//   	return of(control.value).pipe(
//   		delay
//   	);
//   	this.upgradeService.validatePostalCode({countryCode:'at', postalCode: control.value}).subscribe(
//   	)
//     return of({'postalCodeCountryMismatch': true});
//   }
// }