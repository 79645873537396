<div class="lead text-center mt-2 py-4">{{ 'stepper.packagesInfo' | translate }}</div>
<div class="d-flex justify-content-center" *ngIf="!products">
	<mat-spinner class="py-3"></mat-spinner>	
</div>
<div *ngIf="products" [formGroup]="form">
	<div formGroupName="shoppingCart">
		<div class="toggle-billing text-center py-3">
			<mat-slide-toggle color="primary" labelPosition="before" (change)="switchPayment($event.checked)">
				{{ 'stepper.subscriptionYearlySwitch' | translate}}
			</mat-slide-toggle>
			<span class="mat-slide-toggle-content ml-2">{{ 'stepper.subscriptionMonthlySwitch' | translate}}</span>
		</div>
		<input type="hidden" formControlName="paymentPlan">
	  <app-product-selector 
	    [products]="products"
	  	formControlName="basePackage"
	  	[isMonthly]="isMonthly"
		></app-product-selector>
	</div>
</div>
<div class="d-flex justify-content-end">
	<button class="btn btn-primary ml-3" [disabled]="!form.valid" matStepperNext>{{ 'stepper.next' | translate }}</button>
</div>
