import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-step-products',
  templateUrl: './step-products.component.html',
  styleUrls: ['./step-products.component.scss']
})
export class StepProductsComponent implements OnInit {

	public isCompleted = false;
	public form: FormGroup;

	public basePackage = new FormControl('', Validators.required);
	public paymentPlan = new FormControl('yearly');

	@Input() isMonthly = false;
	@Input() products: any;

	@Output() changePayment: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
  	private translate: TranslateService,
  	private fb: FormBuilder
  ) { 
  	this.form = this.fb.group({
  		shoppingCart: this.fb.group({
  			basePackage: this.basePackage,
  			paymentPlan: this.paymentPlan
  		})
		});
  }

  switchPayment(val: boolean) {
  	this.isMonthly = val;
  	val ? this.paymentPlan.setValue('monthly') : this.paymentPlan.setValue('yearly');
  	this.changePayment.emit(val);
  }

  ngOnInit(): void { }

}
