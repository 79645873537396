<div class="form-segment" [formGroup]="form">
	<div class="form-row">
		<div class="col-md-12 py-2">
			<mat-form-field class="full-width" appearance="outline">
				<mat-label>{{ 'invoiceDetails.companyName' | translate}}</mat-label>
				<input type="text" matInput formControlName="companyName" placeholder="{{ 'invoiceDetails.companyName' | translate}}" required>
				<mat-error class="pt-2" *ngIf="companyName.invalid">{{ 'errors.forms.required' | translate }}</mat-error>
			</mat-form-field>
		</div>
	</div>

	<div class="form-row">
		<div class="col-md-8 py-2">
			<mat-form-field class="full-width" appearance="outline">
				<mat-label>{{ 'invoiceDetails.companyStreet' | translate }}</mat-label>
				<input type="text" matInput formControlName="companyStreetField" placeholder="{{ 'invoiceDetails.companyStreet' | translate }}" required>
				<mat-error class="pt-2" *ngIf="companyStreet.invalid">{{ 'errors.forms.required' | translate }}</mat-error>
			</mat-form-field>
		</div>
		<div class="col-md-4 py-2">
			<mat-form-field class="full-width" appearance="outline">
				<mat-label>{{ 'invoiceDetails.companyStreetNr' | translate }}</mat-label>
				<input type="text" matInput formControlName="companyStreetNr" placeholder="{{ 'invoiceDetails.companyStreetNr' | translate }}" required>
				<mat-error class="pt-2" *ngIf="companyStreetNr.invalid">{{ 'errors.forms.required' | translate }}</mat-error>
			</mat-form-field>
		</div>
	</div>

	<div class="form-row">
		<div class="col-md-4 py-2">
			<mat-form-field class="full-width" appearance="outline">
				<mat-label>{{ 'invoiceDetails.companyZipCode' | translate }}</mat-label>
				<!-- postalCodeValidator -->
				<input type="text" matInput formControlName="companyPostalCode"  placeholder="{{ 'invoiceDetails.companyZipCode' | translate }}" required>
				<mat-error class="pt-2" *ngIf="companyPostalCode.invalid">{{ 'errors.forms.postalCode' | translate }}</mat-error>
			</mat-form-field>
		</div>
		<div class="col-md-4 py-2">
			<mat-form-field class="full-width" appearance="outline">
				<mat-label>{{ 'invoiceDetails.companyCity' | translate }}</mat-label>
				<input type="text" matInput formControlName="companyCity" placeholder="{{ 'invoiceDetails.companyCity' | translate }}" required>
				<mat-error class="pt-2" *ngIf="companyCity.invalid">{{ 'errors.forms.required' | translate }}</mat-error>
			</mat-form-field>
		</div>
		<div class="col-md-4 py-2">
			<!-- COUNTRY Select simple -->
			<mat-form-field class="full-width" appearance="outline">
			  <mat-label>{{ 'invoiceDetails.companyCountry' | translate }}</mat-label>
			  <mat-select [formControl]="companyCountry" required>
			  	<mat-option *ngFor="let country of countries" [value]="country">
			        {{ 'countries.' + country | translate }}
			    </mat-option>
			  	<mat-option *ngFor="let country of euCountries" [value]="country">
			        {{ 'countries.euCountries.' + country | translate }}
			    </mat-option>
			  </mat-select>
			</mat-form-field>

		</div>
	</div>

	<div class="form-row">
		<div class="col-md-4 py-2">
			<mat-form-field class="full-width" appearance="outline">
				<mat-label>{{ 'invoiceDetails.vatId' | translate}}</mat-label>
				<input type="text" matInput formControlName="vatId" placeholder="">
				<mat-error class="pt-2" *ngIf="vatId.invalid">{{ 'errors.forms.vatId' | translate }}</mat-error>
			</mat-form-field>
		</div>
	</div>
	<input type="hidden" formControlName="invoicingCompanyName">
	<input type="hidden" formControlName="invoicingCompanyStreet">
	<input type="hidden" formControlName="invoicingCompanyZipCode">
	<input type="hidden" formControlName="invoicingCompanyCity">
	<input type="hidden" formControlName="invoicingCompanyCountry">
</div>
